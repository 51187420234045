import React, { useContext } from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { addDays, format } from "date-fns";
import { EnvType } from "./environment";

export type StimaResponse = {
  commercialZoneValues: Record<string, number>;
  dateTime: string;
  zone: string;
};

type Previsioniquery = {
  date: string;
  forecastDays: number;
  zone: string;
  commercialZone: string;
};
export type PrevisioniResponse = {
  dateTime: string;
  type: string;
  zone: string;
  commercialZone: string;
  value?: number;
};
type Calcoloquery = {
  from: string;
  to: string;
  zone: string;
  commercialZone: string;
};
export type CalcoloKPIRow = {
  from: string;
  to: string;
  zone: string;
  commercialZone: string;
  type: string;
  absPercError: number;
  relativeError: number;
};

export type CalcoloResponse = {
  calcoloKPIs: CalcoloKPIRow[];
  overallMAPE: number;
};

export type FlussiInfoTrasmessiResponse = {
  date: string;
  type: string;
  reliability: number;
};
export type FlussiInfoTrasmessiLogResponse = {
  dateTime: string;
  destination: string;
  dataType: string;
  ril: string;
  scheduledTime: string;
  status: string;
};

export const serviceApi = (config: { env: EnvType; auth: Auth0Client }) => {
  return createApi({
    baseQuery: fetchBaseQuery({
      baseUrl: config.env.service,
      prepareHeaders: (headers: Headers) =>
        config.auth.getTokenSilently().then((token) => {
          headers.set("Authorization", `Bearer ${token}`);
          return headers;
        }),
    }),
    endpoints: (builder) => ({
      getPing: builder.query<string, void>({ query: () => "v1.0/ping" }),
      getStimaDMinus1: builder.query<StimaResponse[], string>({
        query: (dateTime: string) =>
          `v1.0/windcomponent/stimaDMinus1?dateTime=${encodeURIComponent(
            dateTime
          )}`,
      }),
      getPrevisioni: builder.query<PrevisioniResponse[], Previsioniquery>({
        query: (params: Previsioniquery) =>
          `v1.0/windcomponent/previsioni?date=${encodeURIComponent(
            params.date
          )}${
            params.forecastDays ? `&forecastDays=${params.forecastDays}` : ""
          }${params.zone ? `&zone=${params.zone}` : ""}${
            params.commercialZone
              ? `&commercialZone=${params.commercialZone}`
              : ""
          }`,
      }),
      getCalcolokpi: builder.query<CalcoloResponse, Calcoloquery>({
        query: (params) =>
          `v1.0/windcomponent/calcolokpi?from=${encodeURIComponent(
            params.from
          )}&to=${encodeURIComponent(format(addDays(new Date(params.to), 1), "yyyy-MM-dd"))}${
            params.zone ? `&zone=${params.zone}` : ""
          }${
            params.commercialZone
              ? `&commercialZone=${params.commercialZone}`
              : ""
          }`,
      }),
      getFlussiInfoTrasmessi: builder.query<
        FlussiInfoTrasmessiResponse[],
        string
      >({
        query: (date) =>
          `v1.0/windcomponent/flussiinfotrasmessi?date=${encodeURIComponent(
            date
          )}`,
      }),
      getFlussiInfoTrasmessiLog: builder.query<
      FlussiInfoTrasmessiLogResponse[],
        {
          date: string;
          // destination: string;
          // dataType: string;
          // ril: string;
          // status: string;
        }
      >({
        query: (params) =>
          `v1.0/windcomponent/flussiinfotrasmessi/log?date=${encodeURIComponent(
            params.date
          )}`,
      }),
    }),
  });
};

export type ServiceApi = ReturnType<typeof serviceApi>;
export const serviceContext = React.createContext<ServiceApi | undefined>(
  undefined
);
export const useService = <q extends keyof ServiceApi["endpoints"]>(
  query: q,
  params: Parameters<ServiceApi["endpoints"][q]["useQuery"]>
): ReturnType<ServiceApi["endpoints"][q]["useQuery"]> => {
  var service = useContext(serviceContext);
  if (!service) throw new Error("Please provide a Service Provider");

  return (service.endpoints[query] as any).useQuery(...params);
};
