import Logo from "../styles/images/gse-logo.png";
import style from "./authLoader.module.scss";

const AuthLoader = () => {
  return (
    <div className={style.authLoader}>
      <img className="logo" width="200" alt="axpoLogo" src={Logo} />
    </div>
  );
};
export default AuthLoader;
