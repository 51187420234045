import * as t from "io-ts";
import { PathReporter } from "io-ts/PathReporter";
import * as E from "fp-ts/lib/Either";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import { flow, pipe } from "fp-ts/lib/function";
import { sequenceS } from "fp-ts/lib/Apply";

const envParams = t.type({
  clientID: t.string,
  domain: t.string,
  audience: t.string,
  service: t.string,
});
type EnvParams = t.TypeOf<typeof envParams>;

export const env = t.intersection([
  envParams,
  t.type({ callbackUrl: t.string }),
]);
export type EnvType = t.TypeOf<typeof env>;

const errorReporter = <A>(decoder: t.Type<A>) =>
  flow(decoder.decode, E.mapLeft(flow(t.failures, PathReporter.report)));

const getEnvParam = (param: string) =>
  T.fromIO(() => process.env[`REACT_APP_${param}`]);
const devConfig: TE.TaskEither<string[], EnvParams> = pipe(
  sequenceS(T.ApplicativePar)({
    clientID: getEnvParam("clientID"),
    domain: getEnvParam("domain"),
    audience: getEnvParam("audience"),
    service: getEnvParam("service"),
  }),
  T.map(errorReporter(envParams))
);

const callbackUrl = T.fromIO(() => window.location.origin);

const fetcher =
  <A>(url: string, decoder: t.Type<A>) =>
  () =>
    fetch(url)
      .then((x) => x.json())
      .then(errorReporter(decoder), () =>
        E.left<string[], t.TypeOf<t.Type<A>>>(["network error"])
      );

const log = (x: any) => T.fromIO(() => console.log(x));
const connectionStringsConfig: TE.TaskEither<string[], EnvParams> = fetcher(
  "/connectionStrings.js",
  envParams
);

const getConfigEnvironment = pipe(
  T.fromIO(() => process.env.NODE_ENV === "development"),
  T.chain((x) => (x ? devConfig : connectionStringsConfig))
);
const getConfig: T.Task<EnvParams> = pipe(
  getConfigEnvironment,
  TE.getOrElse((e) =>
    pipe(
      log(["error connectionStrings", ...e]),
      T.chain(() => T.delay(5000)(getConfig))
    )
  )
);

export const getEnv: T.Task<EnvType> = pipe(
  T.Do,
  T.apS("url", callbackUrl),
  T.apS("connectionStrings", getConfig),
  T.map((x) => ({ ...x.connectionStrings, callbackUrl: x.url })),
);
