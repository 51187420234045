import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory as createHistory } from "history";
import { getEnv } from "./environment";
import { pipe } from "fp-ts/lib/function";
import { ConnectedRouter } from "connected-react-router";
import { serviceApi, serviceContext } from "./service";
import { chain, bind, rightTask, fromIO, bindTo } from "fp-ts/lib/TaskEither";
import { createAuth, authContext } from "./authentication";

const runApp = pipe(
  bindTo("env")(rightTask(getEnv)),
  bind("auth", createAuth),
  chain((env) =>
    fromIO(() => {
      const history = createHistory();
      const service = serviceApi(env);
      const reduxStore = store({ service, history });

      const render = (Component: any) => {
        ReactDOM.render(
          <React.StrictMode>
            <Provider store={reduxStore}>
              <ConnectedRouter history={history}>
                <serviceContext.Provider value={service}>
                  <authContext.Provider value={env.auth}>
                    <Component />
                  </authContext.Provider>
                </serviceContext.Provider>
              </ConnectedRouter>
            </Provider>
          </React.StrictMode>,
          document.getElementById("root")
        );
      };

      if (module.hot) {
        module.hot.accept("./App.tsx", () => {
          render(App);
        });
      }
      render(App);
    })
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

runApp();
