export function DownloadCSV(title: string, headers: string[], data: any[][]) {
  return () => {
    const body = [headers, ...data].map((x) => x.join(",")).join("\n");

    const blob = new Blob([body], { type: "text/csv" });
    var a = document.createElement("a");
    a.download = title;
    a.href = URL.createObjectURL(blob);
    // a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
}
