import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import { ServiceApi } from "../service";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";

export const store = (dependencies: {
  service: ServiceApi;
  history: History;
}) => {
  const routing = routerMiddleware(dependencies.history);
  return configureStore({
    reducer: {
      router: connectRouter(dependencies.history) as any,
      counter: counterReducer,
      [dependencies.service.reducerPath]: dependencies.service.reducer,
    },
    middleware: (mid) => mid().concat(dependencies.service.middleware, routing),
  });
};

type Store = ReturnType<typeof store>;

export type AppDispatch = Store["dispatch"];
export type RootState = ReturnType<Store["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
