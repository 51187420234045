import Main from "./main";
import AuthLoader from "./authLoader/view";
import Login from "./login/view";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { useContext, useEffect, useState } from "react";
import { authContext } from "./authentication";

type authState = "initial" | "authenticated" | "error";

function AuthenticatedMain() {
  const history = useHistory();
  const auth = useContext(authContext);

  const [authState, setAuthState] = useState<authState>("initial");
  useEffect(() => {
    if (window.location.href.includes("Login")) return;

    if (window.location.search.includes("code")) {
      auth?.handleRedirectCallback().then(
        () => {
          history.push("/");
          setAuthState("authenticated");
        },
        () => history.push("/Login")
      );
    } else {
      auth?.getTokenSilently().then(
        () => setAuthState("authenticated"),
        () => setAuthState("error")
      );
    }
  }, [auth, history]);
  switch (authState) {
    case "initial":
      return <AuthLoader />;
    case "authenticated":
      return <Main />;
    case "error":
      return <Redirect to="/Login" />;
  }
}
function App() {
  return (
    <Switch>
      <Route exact path="/Login" component={Login} />
      <Route component={AuthenticatedMain} />
    </Switch>
  );
}

export default App;
