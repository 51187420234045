import { useContext } from "react";
import { authContext } from "../authentication";
import style from "./login.module.scss"; 

function formatState(state?: string) {
  return state
    ? Object.fromEntries(
        decodeURIComponent(state)
          .split("&")
          .map((x) => x.split("="))
      )
    : "";
}

export function Login(props: { location: { state?: string } }) {
  const auth = useContext(authContext);

  return (
    <div className={style.loginContainer}>
      <div className={style.main}>
        <div className={style.title}>Unauthenticated User</div>
        <div className={style.msg}>
          {formatState(props.location.state).error_description || (
            <>
              You have attempted to access a resource that requires
              authentication, to proceed please Log In.
            </>
          )}
        </div>

        <button onClick={() => auth?.loginWithRedirect()}>Login</button>
      </div>
    </div>
  );
}
export default Login;
