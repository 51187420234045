import { addDays, addHours, startOfDay, subDays } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import itLocal from "date-fns/locale/it";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highmaps from "highcharts/modules/map";
import { useState } from "react";
import Container from "../componentContainer/componentContainer";
import chartData from "./chartdata.json";
import styles from "./stima-1.module.scss";
import ConfigurationModal from "../modal/configurationModal";
import { StimaResponse, useService } from "../service";
import { DownloadCSV } from "../createCSV";

highmaps(Highcharts);

const buildChart = (
  res: Highcharts.SeriesMapDataOptions[]
): Highcharts.Options => ({
  title: {
    text: "",
  },
  chart: { backgroundColor: "transparent" },
  credits: { enabled: false },
  legend: { enabled: false },
  series: [
    {
      type: "map",
      joinBy: ["name", "zone"],
      mapData: chartData,
      data: res,
      nullColor: "#a3a3a3",
      borderColor: "transparent",
    },
  ],
  tooltip: {
    backgroundColor: "rgba(0,0,0,.85)",
    style: { color: "white" },
    formatter: function () {
      const current = this.point as any;
      return `Date: ${dateZonedFmt(current.dateTime, "dd-MMM-yy")}<br>${
        current.zone
      }<br>${Object.entries(current.commercialZoneValues)
        .map(([commercialZone, value]) =>
          value !== null
            ? `${commercialZone}: ${valueParse(value)} MWh/h`
            : null
        )
        .join("<br>")}`;
    },
  },
});

const dateZonedFmt = (date: Date, fmt: string) =>
  format(utcToZonedTime(date, "Europe/Rome"), fmt, {
    locale: itLocal,
  });

const valueParse = (val: any) => (val || val === 0 ? val.toFixed(3) : null);

export function Chart() {
  const [date, setDate] = useState(
    format(subDays(new Date(), 1), "yyyy-MM-dd")
  );
  const [hour, setHour] = useState(0);

  const service = useService("getStimaDMinus1", [
    format(addHours(new Date(date), hour), "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone: "Europe/Rome",
    }),
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const res = (service.data ?? []).map(addColor);

  return (
    <div className={styles.component}>
      <Container
        title="Stima D-1"
        onConfigurationClicked={() => setIsModalOpen(true)}
        onDownloadClicked={DownloadCSV(
          `Stima${formatFileDateTime(date, hour)}`,
          ["DateTime", "Zone", "CommercialZone", "Value"],
          formatCSVValues(res)
        )}
        header={
          <select
            value={hour}
            onChange={(e) => setHour(Number(e.target.value))}
          >
            {Array.from({ length: 24 }).map((_, i) => (
              <option key={i} value={i}>
                {i + 1}
              </option>
            ))}
          </select>
        }
      >
        <>
          {isModalOpen && (
            <Configuration
              date={date}
              hour={hour}
              cancelConfig={() => setIsModalOpen(false)}
              applyConfig={(config) => {
                setDate(config.date);
                setHour(config.hour);
                setIsModalOpen(false);
              }}
            ></Configuration>
          )}
          {service.isFetching || (
            <HighchartsReact
              highcharts={Highcharts}
              options={buildChart(res)}
              constructorType={"mapChart"}
            />
          )}
        </>
      </Container>
    </div>
  );
}
function Configuration(props: {
  date: string;
  hour: number;
  applyConfig: (config: { date: string; hour: number }) => void;
  cancelConfig: () => void;
}) {
  const [date, setDate] = useState(props.date);
  const [hour, setHour] = useState(props.hour);
  return (
    <ConfigurationModal
      title="Stima D-1"
      closeModal={props.cancelConfig}
      applyConfiguration={() => props.applyConfig({ date, hour })}
    >
      <>
        <div>
          Report Date:{" "}
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            max={format(addDays(startOfDay(new Date()), -1), "yyyy-MM-dd")}
          />
        </div>
        <div>
          Hour:{" "}
          <select
            value={hour}
            onChange={(e) => setHour(Number(e.target.value))}
          >
            {Array.from({ length: 24 }).map((_, i) => (
              <option key={i} value={i}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
      </>
    </ConfigurationModal>
  );
}

function formatCSVValues(res: StimaResponse[]): (string | number)[][] {
  return res.flatMap((x: StimaResponse) =>
    Object.entries(x.commercialZoneValues).map(([zone, value]) => [
      x.dateTime,
      x.zone,
      zone,
      value,
    ])
  );
}

function formatFileDateTime(date: string, hour: number): string {
  return `${format(new Date(date), "yyyyMMdd", {
    timeZone: "Europe/Rome",
  })}_${(hour + 1).toString().padStart(2, "0")}`;
}

function addColor<A extends { zone: string }>(a: A) {
  return { ...a, color: colorLookup[a.zone] };
}
const colorLookup: Record<string, string> = {
  CALA: "#7BD8F1",
  CNOR: "#ffb86e",
  CSUD: "#a9eeed",
  NORD: "#2a6996",
  SARD: "#f3e35d",
  SICI: "#4bc87d",
  SUD: "#d93753",
};
