import Modal from "./modal";
import styles from "./style.module.scss";

export default function ConfigurationModal(props: {
  title: string;
  closeModal: () => void;
  applyConfiguration: () => void;
  children: JSX.Element;
}) {
  return (
    <Modal closeModal={props.closeModal}>
      <div className={styles.configurationModal}>
        <div className="modal-header">
          Configuration for {props.title}
          <button className="fa fa-times" onClick={props.closeModal}></button>
        </div>
        <div className="modal-body">{props.children}</div>
        <div className="modal-footer">
          <button onClick={props.closeModal}>Cancel</button>
          <button onClick={props.applyConfiguration}>Apply</button>
        </div>
      </div>
    </Modal>
  );
}
