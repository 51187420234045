import style from "./componentContainer.module.scss";

export default function Container(props: {
  title: string;
  onConfigurationClicked: () => void;
  onDownloadClicked: () => void;
  header?: JSX.Element;
  children?: JSX.Element;
}) {
  return (
    <div className={style.componentContainer}>
      <div className="head">
        <b>{props.title}</b>
        <div>
          {props.header}
          <button
            className="fa fa-download"
            onClick={props.onDownloadClicked}
          ></button>
          <button
            className="fa fa-cog"
            onClick={props.onConfigurationClicked}
          ></button>
        </div>
      </div>
      <div className="body">{props.children}</div>
    </div>
  );
}
