import {
  addDays,
  eachHourOfInterval,
  endOfDay,
  format,
  startOfDay,
  subDays,
} from "date-fns";
import itLocal from "date-fns/locale/it";
import { findFirst, map, scanLeft } from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import { groupBy } from "fp-ts/lib/NonEmptyArray";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import { useState } from "react";
import Container from "../componentContainer/componentContainer";
import zones from "../zoneMapping.json";
import ConfigurationModal from "../modal/configurationModal";
import { getOrElse } from "fp-ts/lib/Option";
import { PrevisioniResponse, useService } from "../service";
import { utcToZonedTime } from "date-fns-tz";
import { DownloadCSV } from "../createCSV";
import { clamp } from "fp-ts/lib/Ord";
import { number } from "fp-ts";
import * as R from "ramda";

//required for Chart type AreaRange
highchartsMore(Highcharts);

const buildChart = (
  res: any,
  // res: Highcharts.SeriesLineOptions[],
  allHoursInRange: Date[],
  zone: keyof typeof zones,
  commercialZone: string
): Highcharts.Options => ({
  title: {
    text: `${zone} - ${commercialZone}`,
    style: { color: "white" },
  },
  yAxis: { title: { text: "MW", style: { color: "white" } } },
  xAxis: [
    {
      startOnTick: true,
      categories: allHoursInRange.map((_, i) => `${(i % 24) + 1}`) ?? [],
      tickmarkPlacement: "on",
      tickInterval: 1,
    },
    {
      tickmarkPlacement: "on",
      tickPositions: pipe(
        allHoursInRange.map((x) => dateZonedFmt(x, "dd-MMM")) ?? [],
        groupBy((x) => x),
        Object.values,
        scanLeft(0, (_, x) => _ + x.length)
      ),
      linkedTo: 0,
      lineWidth: 0,
      gridLineWidth: 1,
      categories: allHoursInRange.map((x) => dateZonedFmt(x, "dd-MMM")) ?? [],
    },
  ],
  legend: {
    itemStyle: { color: "white" },
  },
  chart: { backgroundColor: "transparent", type: "line" },
  credits: { enabled: false },
  series: res,
  tooltip: {
    shared: true,
    backgroundColor: "rgba(0,0,0,.85)",
    style: { color: "white" },
    formatter: function () {
      const current = this.points?.[0]?.point as any;
      return `Date: ${dateZonedFmt(current.dateTime, "dd-MMM-yy")}<br>Hour: ${
        this.x
      }<br>${this.points
        ?.map((x: any) => {
          if (x.key === "Confidence Area")
            return `${x.key}<br>  Low:${x.point.low.toFixed(
              3
            )} MW  <br> High:${x.point.high.toFixed(3)} MW`;

          return `${x.series.name}: ${x.point.value.toFixed(3)} MW`;
        })
        .join("<br>")}`;
    },
  },
});

const dateZonedFmt = (date: Date, fmt: string) =>
  format(utcToZonedTime(date, "Europe/Rome"), fmt, {
    locale: itLocal,
  });

export function Chart() {
  const [zone, setZone] = useState<keyof typeof zones>("NORD");
  const [commercialZone, setCommercialZone] = useState(zones[zone][0]);

  const [date, setDate] = useState(
    format(startOfDay(new Date()), "yyyy-MM-dd")
  );
  const [forecastDays, setForecastDays] = useState(4);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const service = useService("getPrevisioni", [
    { date, forecastDays, zone, commercialZone },
  ]);

  const chartData = service.data
    ? service.data.reduce(
        (curr: any, acc: any) => {
          if (
            acc.type === "ConfidenceAreaMin" ||
            acc.type === "ConfidenceAreaMax"
          ) {
            return { ...curr, area: R.append(acc, curr.area) };
          }

          return { ...curr, line: R.append(acc, curr.line) };
        },
        { line: [], area: [] }
      )
    : { line: [], area: [] };

  const res = processLineData(chartData.line);
  const areaRangeData = processAreaRangeData(chartData.area) as any;
  const allHoursInRange = eachHourOfInterval({
    start: subDays(startOfDay(new Date(date)), 1),
    end: addDays(endOfDay(new Date(date)), forecastDays),
  });

  return (
    <div>
      <Container
        title="Previsioni"
        onConfigurationClicked={() => setIsModalOpen(true)}
        onDownloadClicked={DownloadCSV(
          `Previsioni${date}`,
          ["DateTime", "Type", "Zone", "CommercialZone", "Value"],
          formatCSVValues(service.data ?? [])
        )}
      >
        <>
          {isModalOpen && (
            <Configuration
              zones={zones}
              date={date}
              zone={zone}
              commercialZone={commercialZone}
              forecastDays={forecastDays}
              cancelConfig={() => setIsModalOpen(false)}
              applyConfig={(config) => {
                setDate(config.date);
                setZone(config.zone);
                setCommercialZone(config.commercialZone);
                setForecastDays(config.forecastDays);
                setIsModalOpen(false);
              }}
            ></Configuration>
          )}
          {service.isFetching || (
            <HighchartsReact
              highcharts={Highcharts}
              options={buildChart(
                res.concat(areaRangeData),
                allHoursInRange,
                zone,
                commercialZone
              )}
              constructorType={"mapChart"}
            />
          )}
        </>
      </Container>
    </div>
  );
}

function Configuration(props: {
  zones: Record<string, string[]>;
  date: string;
  zone: keyof typeof zones;
  commercialZone: string;
  forecastDays: number;
  applyConfig: (config: {
    date: string;
    zone: keyof typeof zones;
    commercialZone: string;
    forecastDays: number;
  }) => void;
  cancelConfig: () => void;
}) {
  const [date, setDate] = useState(props.date);
  const [zone, setZone] = useState<keyof typeof zones>(props.zone);
  const [commercialZone, setCommercialZone] = useState(props.commercialZone);
  const [forecastDays, setForecastDays] = useState(props.forecastDays);

  return (
    <ConfigurationModal
      title="Previsioni"
      closeModal={props.cancelConfig}
      applyConfiguration={() =>
        props.applyConfig({
          date,
          zone,
          commercialZone,
          forecastDays,
        })
      }
    >
      <>
        <div>
          Report Date:{" "}
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            max={format(startOfDay(new Date()), "yyyy-MM-dd")}
          />
        </div>
        <div>
          Zone:{" "}
          <select
            value={zone}
            onChange={(e) => {
              const zone = e.target.value;
              setZone(zone as keyof typeof zones);

              setCommercialZone(
                pipe(
                  props.zones[zone],
                  findFirst((x) => x === commercialZone),
                  getOrElse(() => props.zones[zone][0])
                )
              );
            }}
          >
            {Object.keys(props.zones).map((zone) => (
              <option key={zone}>{zone}</option>
            ))}
          </select>
        </div>
        <div>
          Commercial Zone:{" "}
          <select
            value={commercialZone}
            onChange={(e) => setCommercialZone(e.target.value)}
          >
            {props.zones[zone].map((zone) => (
              <option key={zone}>{zone}</option>
            ))}
          </select>
        </div>
        <div>
          Number of Days Forecast:{" "}
          <input
            type="number"
            value={forecastDays}
            onChange={(e) => {
              const value = pipe(
                e.target.value,
                Number,
                clamp(number.Ord)(1, 30)
              );

              setForecastDays(value);
            }}
            max="30"
            min="1"
          />
        </div>
      </>
    </ConfigurationModal>
  );
}

function processLineData(
  data: { type: string; dateTime: string; value: number }[]
) {
  return pipe(
    data,
    map(toXY),
    groupBy((x) => x.type),
    Object.entries,
    map(
      ([name, data]) =>
        ({
          type: "line",
          name: setChartLabelName(name),
          data,
        } as const)
    )
  );
}

function processAreaRangeData(
  data: { type: string; dateTime: string; value: number }[]
) {
  const retVal = pipe(
    data,
    map(toXY),
    groupBy((x) => x.dateTime),
    Object.entries,
    map(
      ([dateTime, data]) =>
        ({
          dateTime: new Date(dateTime),
          low: data[0].value ?? null,
          high: data[1].value ?? null,
          name: "Confidence Area",
        } as const)
    )
  );
  return [
    {
      type: "arearange",
      data: retVal,
      name: "Confidence Area",
    },
  ] as const;
}

function toXY<A extends { dateTime: string; value: number }>(a: A) {
  return {
    ...a,
    dateTime: new Date(a.dateTime),
    y: a.value,
  };
}

function formatCSVValues(
  res: PrevisioniResponse[]
): (string | number | undefined)[][] {
  return res.map((x: PrevisioniResponse) => [
    x.dateTime,
    setChartLabelName(x.type),
    x.zone,
    x.commercialZone,
    x.value,
  ]);
}

const setChartLabelName = (name: string) => {
  switch (name) {
    case "ActualDMinus1":
      return "Stima D-1";
    case "MGPForecast":
      return "MGP Forecast";
    case "LongTermForecast":
      return "Long Term Forecast";
    case "ConfidenceArea Min":
      return "Confidence Area Min";
    case "ConfidenceAreaMax":
      return "Confidence Area Max";
    default:
      return name;
  }
};
