import { useState } from "react";
import {
  addDays,
  addMonths,
  clamp,
  format,
  startOfDay,
  subDays,
} from "date-fns";
import Container from "../componentContainer/componentContainer";
import { CalcoloKPIRow, CalcoloResponse, useService } from "../service";
import zones from "../zoneMapping.json";
import styles from "./calcoloKPI.module.scss";
import { pipe } from "fp-ts/lib/function";
import { findFirst } from "fp-ts/lib/Array";
import { getOrElse } from "fp-ts/lib/Option";
import ConfigurationModal from "../modal/configurationModal";
import { DownloadCSV } from "../createCSV";

export default function CalcoloKPI() {
  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const [from, setFrom] = useState(
    format(addMonths(startOfDay(new Date()), -3), "yyyy-MM-dd")
  );
  const [to, setTo] = useState(
    format(addMonths(startOfDay(new Date()), -2), "yyyy-MM-dd")
  );
  const [zone, setZone] = useState<keyof typeof zones>("NORD");
  const [commercialZone, setCommercialZone] = useState(zones[zone][0]);

  const service = useService("getCalcolokpi", [
    { from, to, zone, commercialZone },
  ]);

  const res = service.data ?? null;

  return (
    <Container
      title="Calcolo KPI"
      onConfigurationClicked={() => setIsConfigOpen(true)}
      onDownloadClicked={DownloadCSV(
        `Calcolo KPI${from}_${to}`,
        [
          "Modello forecast considerato",
          "Periodo di riferimento",
          "Errore Assoluto",
          "Errore relativo",
        ],
        formatCSVValues(service.data ?? null)
      )}
    >
      <div className={styles.calcolo}>
        {isConfigOpen && (
          <Configuration
            from={from}
            to={to}
            zone={zone}
            commercialZone={commercialZone}
            applyConfig={(config) => {
              setTo(config.to);
              setFrom(config.from);
              setZone(config.zone);
              setCommercialZone(config.commercialZone);
              setIsConfigOpen(false);
            }}
            cancelConfig={() => setIsConfigOpen(false)}
          />
        )}
        {service.isFetching || (
          <>
            <table>
              <thead>
                <tr>
                  <th>Modello forecast considerato</th>
                  <th>Periodo di riferimento</th>
                  <th>Errore Assoluto</th>
                  <th>Errore relativo</th>
                </tr>
              </thead>
              <tbody>
                {res.calcoloKPIs.map((x: CalcoloKPIRow) => (
                  <tr key={x.type}>
                    <td>{x.type} </td>
                    <TdPeriod row={x} />
                    <td>{percVal(x.absPercError)}</td>
                    <td>{percVal(x.relativeError)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className={styles.mapeTable}>
              <thead>
                <tr>
                  <th>Periodo di riferimento</th>
                  <th>MAPE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TdPeriod row={res.calcoloKPIs[0]} />
                  <td>{percVal(res.overallMAPE)}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </Container>
  );
}
const percVal = (val: number | null) => {
  if (val === null || val === 0) return "0%";

  return `${(val * 100).toFixed(2)}%`;
};

function TdPeriod(props: { row: CalcoloKPIRow }) {
  const to = format(subDays(new Date(props.row.to), 1), "yyyy-MM-dd");
  return (
    <>
      <td>
        {props.row.from}
        <br />
        {to}
      </td>
    </>
  );
}

function Configuration(props: {
  from: string;
  to: string;
  zone: keyof typeof zones;
  commercialZone: string;
  applyConfig: (config: {
    from: string;
    to: string;
    zone: keyof typeof zones;
    commercialZone: string;
  }) => void;
  cancelConfig: () => void;
}) {
  const [from, setFrom] = useState(props.from);
  const [to, setTo] = useState(props.to);
  const [zone, setZone] = useState<keyof typeof zones>(props.zone);
  const [commercialZone, setCommercialZone] = useState(props.commercialZone);

  return (
    <ConfigurationModal
      title="Calcolo KPI"
      closeModal={props.cancelConfig}
      applyConfiguration={() =>
        props.applyConfig({
          from,
          to,
          zone,
          commercialZone,
        })
      }
    >
      <>
        <div>
          From:{" "}
          <input
            type="date"
            value={from}
            onChange={(e) => {
              const fromDate = new Date(e.target.value);
              const toDate = clamp(new Date(to), {
                start: fromDate,
                end: addDays(fromDate, 365),
              });
              setFrom(e.target.value);
              setTo(format(toDate, "yyyy-MM-dd"));
            }}
            max={format(startOfDay(new Date()), "yyyy-MM-dd")}
          />
        </div>
        <div>
          To:{" "}
          <input
            type="date"
            value={to}
            onChange={(e) => {
              const toDate = new Date(e.target.value);
              const fromDate = clamp(new Date(from), {
                end: toDate,
                start: addDays(toDate, -365),
              });
              setTo(e.target.value);
              setFrom(format(fromDate, "yyyy-MM-dd"));
            }}
            max={format(startOfDay(new Date()), "yyyy-MM-dd")}
          />
        </div>
        <div>
          Zone:{" "}
          <select
            value={zone}
            onChange={(e) => {
              const zone = e.target.value as keyof typeof zones;
              setZone(zone);

              setCommercialZone(
                pipe(
                  zones[zone],
                  findFirst((x) => x === commercialZone),
                  getOrElse(() => zones[zone][0])
                )
              );
            }}
          >
            {Object.keys(zones).map((zone) => (
              <option key={zone}>{zone}</option>
            ))}
          </select>
        </div>
        <div>
          Commercial Zone:{" "}
          <select
            value={commercialZone}
            onChange={(e) => setCommercialZone(e.target.value)}
          >
            {zones[zone].map((zone) => (
              <option key={zone}>{zone}</option>
            ))}
          </select>
        </div>
      </>
    </ConfigurationModal>
  );
}

function formatCSVValues(
  res: CalcoloResponse
): (string | number | undefined)[][] {
  return res == null
    ? []
    : res.calcoloKPIs.map((x: CalcoloKPIRow) => [
        `${x.from}_${x.to}`,
        x.type,
        x.absPercError,
        x.relativeError,
      ]);
}
