import { useState, useEffect, useContext } from "react";
import styles from "./header.module.scss";
import logo from "../styles/images/gse-logo.png";
import { Link } from "react-router-dom";
import { User } from "@auth0/auth0-spa-js";
import { authContext } from "../authentication";

const UserMenuBody = (props: { user: User; close: () => void }) => {
  useMenuCloseListener({
    close: props.close,
    selector: ".userMenu",
  });
  const auth = useContext(authContext);
  return (
    <>
      <div className="main">
        <div className="userInfo">
          Logged in as <span className="email">{props.user.email}</span>
        </div>
        <button className="signOut" onClick={() => auth?.logout()}>
          Sign Out <div className="fa fa-sign-out-alt icon"></div>
        </button>
      </div>
    </>
  );
};

const HeaderView = () => {
  const [isOpen, setIsOpen] = useState(false);

  const auth = useContext(authContext);

  const [user, setUser] = useState<User>();
  useEffect(() => {
    auth?.getUser().then((u) => setUser(u));
  }, [auth]);

  return (
    <>
      <div className={`${styles.header}`}>
        <div className="logo-section">
          <Link to="/" className="link">
            <img className="logo" alt="axpoLogo" src={logo} />
          </Link>
        </div>
        {user && (
          <div className={styles.companyAndUserSelect}>
            <div className="header-menu userMenu">
              <div className="header" onClick={() => setIsOpen((s) => !s)}>
                <div className="far fa-user-circle icon"></div>
                <div className="userContainer">
                  <div className="header-menu-header">{user.name}</div>
                </div>
                <div className="fa fa-chevron-down"></div>
              </div>
              {isOpen ? (
                <div className="body">
                  <UserMenuBody user={user} close={() => setIsOpen(false)} />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

function useMenuCloseListener(options: {
  close: () => void;
  selector: string;
}) {
  useEffect(() => {
    function listener(e: MouseEvent) {
      if ((e.target as HTMLElement | null)?.closest(options.selector)) return;
      options.close();
    }

    document.body.addEventListener("click", listener);
    return () => {
      document.body.removeEventListener("click", listener);
    };
  });
}

export default HeaderView;
