import { useEffect, useState } from "react";
import { addMonths, format, startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import itLocal from "date-fns/locale/it";
import Container from "../componentContainer/componentContainer";
import {
  FlussiInfoTrasmessiLogResponse,
  FlussiInfoTrasmessiResponse,
  useService,
} from "../service";
import styles from "./flussiInformativi.module.scss";
import ConfigurationModal from "../modal/configurationModal";
import { DownloadCSV } from "../createCSV";

export default function FlussiInformativi() {
  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const [date, setDate] = useState(
    format(addMonths(startOfDay(new Date()), -1), "yyyy-MM")
  );
  const service = useService("getFlussiInfoTrasmessi", [date]);

  const [downloadDate, setDownloadDate] = useState("");
  const extractionData = useService("getFlussiInfoTrasmessiLog", [
    { date: downloadDate },
    { skip: !downloadDate },
  ]);
  useEffect(() => {
    if (!extractionData.data) return;

    DownloadCSV(
      `Flussi Informativi Trasmessi${date}`,
      [
        "DateTime",
        "Destination",
        "DataType",
        "Ril",
        "Scheduled Time",
        "Status",
      ],
      formatCSVValues(extractionData.data ?? [])
    )();
  }, [extractionData.data]);

  const res = service.data ?? [];

  return (
    <Container
      title="Flussi Informativi Trasmessi"
      onConfigurationClicked={() => setIsConfigOpen(true)}
      onDownloadClicked={() => {
        setDownloadDate(date);
      }}
    >
      <div className={styles.calcolo}>
        {isConfigOpen && (
          <Configuration
            date={date}
            applyConfig={(config) => {
              setDate(config.date);
              setIsConfigOpen(false);
            }}
            cancelConfig={() => setIsConfigOpen(false)}
          />
        )}
        {service.isFetching || (
          <table>
            <thead>
              <tr>
                <th>Nome Emissione</th>
                <th>Periodo</th>
                <th>Affidabilità</th>
              </tr>
            </thead>
            <tbody>
              {res.map((x: FlussiInfoTrasmessiResponse) => (
                <tr key={x.type + x.date}>
                  <td>{x.type}</td>
                  <td>{dateZonedFmt(new Date(x.date))}</td>
                  <td>{percVal(x.reliability)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Container>
  );
}

const dateZonedFmt = (date: Date) => {
  var month = format(utcToZonedTime(date, "Europe/Rome"), "MMMM", {
    locale: itLocal,
  });

  return month.charAt(0).toUpperCase() + month.slice(1);
};

const percVal = (val: number | null) => {
  if (val === null || val === 0) return "0%";

  return `${(val * 100).toFixed(2)}%`;
};

function Configuration(props: {
  date: string;
  applyConfig: (config: { date: string }) => void;
  cancelConfig: () => void;
}) {
  const [date, setDate] = useState(props.date);

  return (
    <ConfigurationModal
      title="Flussi Informativi Trasmessi"
      closeModal={props.cancelConfig}
      applyConfiguration={() =>
        props.applyConfig({
          date,
        })
      }
    >
      <>
        <div>
          From:{" "}
          <input
            type="month"
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
            max={format(startOfDay(new Date()), "yyyy-MM-dd")}
          />
        </div>
      </>
    </ConfigurationModal>
  );
}

function formatCSVValues(
  res: FlussiInfoTrasmessiLogResponse[]
): (string | number | undefined)[][] {
  return res.map((x) => [
    x.dateTime,
    x.destination,
    x.dataType,
    x.ril,
    x.scheduledTime,
    x.status,
  ]);
}
