import HeaderView from "./header/view";
import style from "./App.module.scss";
import { Chart as StimaD1Chart } from "./stimad-1/stima-1";
import { Chart as PrevisionoiChart } from "./previsioni/previsioni";
import CalcoloKPI from "./calcoloKPI/calcoloKPI";
import FlussiInformativi from "./flussiInformativi/flussiInformativi";

const Main = () => {
  return (
    <div className={style.App}>
      <HeaderView />
      <div className={style.main}>
        <StimaD1Chart />
        <PrevisionoiChart />
        <CalcoloKPI />
        <FlussiInformativi />
      </div>
      {/* <Footer />
      <CustomPrompt />
      <ErrorModal /> */}
    </div>
  );
};

export default Main;
