import { useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./style.module.scss";

function getRoot() {
  const elem = document.getElementById("modalRoot");
  if (!elem) throw new Error("modalRoot not found");
  return elem;
}

export default function Modal(props: {
  children?: JSX.Element;
  closeModal: () => void;
}) {
  useEffect(() => {
    var html = document.scrollingElement ?? document.body;
    html.classList.add("showingModal");

    return () => {
      html.classList.remove("showingModal");
    };
  }, []);
  return ReactDOM.createPortal(
    <>
      <div
        className={styles.modal}
        onClick={(e) => {
          if (e.currentTarget === e.target) props.closeModal();
        }}
      >
        {props.children}
      </div>
    </>,
    getRoot()
  );
}
