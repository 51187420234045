import React from "react";
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import { tryCatch } from "fp-ts/lib/TaskEither";
import { EnvType } from "./environment";

export const createAuth = (config: { env: EnvType }) =>
  tryCatch(
    () => {
      return createAuth0Client({
        domain: config.env.domain,
        client_id: config.env.clientID,
        audience: config.env.audience,
        redirect_uri: config.env.callbackUrl,
        useRefreshTokens: true,
        cacheLocation: "localstorage",
        scope: "openid profile email",
      });
    },
    () => {
      console.log("auth0 setup failed");
      return "auth0 failure";
    }
  );

export const authContext = React.createContext<Auth0Client | undefined>(undefined);
